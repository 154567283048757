import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function Watch() {
    const params = new URLSearchParams(window.location.search);
    const videoId = params.get('v');

    if (!videoId) {
        return <h2>Video not found</h2>;
    }

    return (
        <div className="app-container">
            <header className="header">
                quityoutube.com
            </header>
            <div className="video-container">
                <iframe
                    id="responsive-iframe"
                    className="responsive-iframe"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
}

function Home() {
    return <p>Home Page</p>
}

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/watch" element={<Watch />} />
            </Routes>
        </Router>
    );
}